import { Component, OnInit, Input } from '@angular/core';
import {NewsService} from '../news/news.service';
import {NewsComponent} from '../news/news.component';

@Component({
  selector: 'app-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.css']
})
export class CardViewComponent implements OnInit {

  public index;
  public isFeatured: boolean;
  public featuredNewsDetails: object = {};
  public newsInfoDetails: object = {};
  public regularNews: object = {};
  public cardViewFeaturedNews: object = {};

  constructor(private newsService: NewsService) { }

  ngOnInit() {
    this.featuredNewsDetails = this.newsService.loadFeaturedDetails();
    this.newsInfoDetails = this.newsService.loadNewsInfoDetails();
    this.regularNews = this.newsService.loadNewsInfo();
    this.cardViewFeaturedNews = this.newsService.loadFeaturedNews();
    this.index = this.newsService.getIndex();
    this.isFeatured = this.newsService.getFeatured();
  }

}
