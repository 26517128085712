import { Router, RouterModule } from '@angular/router';
import {NewsComponent} from './news/news.component';
import {ContactComponent} from './contact/contact.component';
import {AboutComponent} from './about/about.component';
import {CardViewComponent} from './card-view/card-view.component';
import {PrivacyComponent} from './privacy/privacy.component';

export const Routing = RouterModule.forRoot([
  {path: '', redirectTo: '/news', pathMatch: 'full'},
  {path: 'news', component: NewsComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'about', component: AboutComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'news/card-view', component: CardViewComponent},
  {path: 'news/card-view/:id', component: CardViewComponent}
]);
