import { Component, OnInit } from '@angular/core';
import {Router, Event, NavigationEnd} from '@angular/router';
import {NewsService} from './news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  public featuredNews: object = {};
  public regularNews: object = {};
  public i = 0;
  public isFeatured = false;
  public subscription;

  constructor(private news: NewsService, private router: Router) { }

  ngOnInit() {
    this.featuredNews = this.news.loadFeaturedNews();
    this.regularNews = this.news.loadNewsInfo();
    this.subscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        console.log(event.url);
        if (event.url !== '/news/card-view') {
          this.isFeatured = false;
          this.news.setFeatured(this.isFeatured);
        }
      }
    });
  }

  passIndexValue(index) {
    this.i = index;
    this.news.setIndex(this.i);
  }

  setIsFeatured() {
    this.isFeatured = true;
    this.news.setFeatured(this.isFeatured);
  }
}
