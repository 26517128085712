import { Injectable } from '@angular/core';

@Injectable()
export class NewsService {
  private isFeatured: boolean;
  private index;
  private id = 0;
  loadFeaturedNews(): object {
    return [
      {
        'image': '../assets/img/app-home-screen.png',
        'title': 'KuhlmanTech\'s App (Updated)',
        'description': 'We have a volleyball score keeping app currently available for Android and we are in the process of releasing it ' +
        'to the Apple App Store in the near future.'
      }
    ];
  }
  loadNewsInfo(): object {
    return [
      {
        'id': 0,
        'image': '../assets/img/anker/anker_headphone_box.png',
        'title': '(Review) Anker Soundcore Life Q20',
        'description': 'We have a new Anker product to review!'
      },
      {
        'id': 1,
        'image': '../assets/img/tronsmart/tronsmart_speaker_box.png',
        'title': '(Review) Tronsmart Element T6 Plus',
        'description': 'Tronsmart sent us a Bluetooth Speaker to review!'
      },
      {
        'id': 2,
        'image': '../assets/img/aukey/aukey_pwrbnk_big_box.png',
        'title': '(Review) Aukey 20,000mah USB-C Power Bank',
        'description': 'Aukey sent us a big USB-C Power Bank to review!'
      },
      {
        'id': 3,
        'image': '../assets/img/aukey/aukey_lamp.png',
        'title': '(Review) Aukey Touch-Sensitive Table Lamp',
        'description': 'Aukey sent us an LED touch lamp to review!'
      },
      {
        'id': 4,
        'image': '../assets/img/aukey/power-bank-and-cable.png',
        'title': '(Review) Aukey USB-C Power Bank',
        'description': 'Aukey sent us a USB-C Power Bank to review!'
      },
      {
        'id': 5,
        'image': '../assets/img/aukey/wireless-charge-and-cable.png',
        'title': '(Review) Aukey Wireless Fast Charger',
        'description': 'Aukey sent us a Wireless Fast Charger to review!'
      },
      {
        'id': 6,
        'image': '../assets/img/aukey/dash-cam-4.png',
        'title': '(Review) Aukey 1080p Dash Cam',
        'description': 'Aukey sent us a 1080p Dash Cam to review!'
      },
      {
        'id': 7,
        'image': '../assets/img/tronsmart/tronsmart_box.png',
        'title': '(Review) Tronsmart Element Mega',
        'description': 'Tronsmart sent us a Speaker to review!'
      },
      {
        'id': 8,
        'image': '../assets/img/aukey/keyboard_box.png',
        'title': '(Review) Aukey RGB Gaming Keyboard',
        'description': 'Aukey sent another goodie! An RGB Gaming Keyboard!'
      },
      {
        'id': 9,
        'image': '../assets/img/aukey/aukey-charge-cable.png',
        'title': '(Review) Aukey USB-C MacBook Charging Cable',
        'description': 'Aukey sent us a charging cable for the MacBook to review.'
      },
      {
        'id': 10,
        'image': '../assets/img/aukey/aukey-hub.png',
        'title': '(Review) Aukey USB-C to 3.0 Hub',
        'description': 'Aukey sent us a USB-C to USB 3.0 Hub to review.'
      },
      {
        'id': 11,
        'image': '../assets/img/anker/anker-in-box.png',
        'title': '(Review) Anker Soundcore 2',
        'description': 'We reviewed a bluetooth speaker for Anker!'
      },
      {
        'id': 12,
        'image': '../assets/img/apple-store-icon.png',
        'title': 'The App Is Available for iPhones!',
        'description': 'The app is finally available on the Apple App Store'
      }
      // COPY THIS TO ADD MORE ARTICLES
      // {
      //   'id': 3,
      //   'image': '',
      //   'title': '',
      //   'description': ''
      // },
    ];
  }
  loadFeaturedDetails(): object {
    return [
      {
        'details': '<h2>About The App</h2><br>' +
        '<p>Safe Sets is the name of our volleyball score keeping app. It has been available in the Android Google Play ' +
        'Store for some time now. We released it there first, because Google was less expensive than apple to release an app. This made ' +
        'it possible for us to test the waters and see what people thought of the app. This was mostly built for a few friends and we ' +
        'didn\'t expect as many downloads as we have had.</p>' +
        '<p>Over the last year we have had significant increase in requests for an iPhone version. After working out all the bugs ' +
        'on the Android side, we started building the app for Apple. The release is in the works, but not only is Apple expensive, but ' +
        'they have a very intricate process and more requirements than expected. We are working hard to bring this to Apple as fast as ' +
        'we can.</p>' +
        '<h2>How The App Works</h2><br>' +
        '<p>The app is exactly what you think. It keeps score of your volleyball matches. So why would you want this app over ' +
        'another? This app has advanced tracking features specific to outdoor volleyball rules. If you are sitting in the stands or ' +
        'reffing a game from the sidelines, it will track everything you need.</p>' +
        '<p>When you select outdoor game from the main screen it will bring you to a set up screen. This allows you to choose how ' +
        'many points your match will go to, after how many points scored the teams need to switch sides and if there is a cap to the ' +
        'match. After you set up that information, you are brought to the game screen. A pop up will appear asking for team numbers and ' +
        'players names. After you give it that info you will see one players name in red and the other in black for both teams. The ' +
        'red indicates it is that persons serve. That\'s right, it tracks who is suppose to be serving. After adding points to the score ' +
        'you will eventually come to a pop up that tells the teams to switch sides. You will notice once you have cleared this pop up ' +
        'that all the information on the screen is swapped! This makes it convenient for you to have the right team on the right side ' +
        'of the net. There are overrides in the three dot menu located at the top right of the screen, just in case something is amiss.' +
        '</p>' +
        '<p>That\'s the app! You can currently go ' +
        '<a href="https://play.google.com/store/apps/details?id=com.KuhlmanTech" target="_blank">here</a> to the ' +
        'Google Play store to download it, or go <a href="https://itunes.apple.com/us/app/safe-sets/id1396956632?ls=1&mt=8">here</a> to ' +
        'the Apple App Store to download it.</p><br>'
      }
    ];
  }
  loadNewsInfoDetails(): object {
    return [
      {
        'id': 0,
        'details': '<h4>Anker Soundcore Life Q20</h4>' +
          '<br>' +
          'We have a new device from Anker in house and it is awesome! \n' +
          'The Anker Soundcore Life Q20 bluetooth headphones are not \n' +
          'what I had expected from a $60 pair of headphones. The sound, \n' +
          'the weight, the build quality, and the style are all something \n' +
          'to appreciate from this pair of headphones. If you are looking \n' +
          'for an expensive sounding pair of headphones for a small price \n' +
          'look no further. I will go over all of the specs of the headphones \n' +
          'in detail below. \n' +
          '<br>' +
          '<h4>General Specs</h4>' +
          '<p>Lets start with what these headphones have to offer. The first item ' +
          'to note about these headphones is that they have bluetooth 5.0. This ' +
          'means their range is ridiculously far and they are low energy data ' +
          'transfer headphones. This is how this pair of headphones packs a 30 ' +
          'hour battery life with all the extras turned on. You can get up to 60 ' +
          'hours if you have a couple features turned off and don\'t need the volume ' +
          'very loud. They offer noise cancellation and a bass boost function. The ' +
          'padding around your ear is memory foam and my favorite part is that it ' +
          'actually goes around your ear and does not sit on it. They are light and ' +
          'have great sound. The bass is the most impressive part of these headphones. ' +
          'People that do not want bass may want to stay clear of these, but from my ' +
          'testing I really enjoyed the sound. They came with an auxiliary cable, ' +
          'charging cable, and a carry pouch.</p> ' +
          '<br>' +
          '<h4>Sound</h4>' +
          '<p>Lets talk about the sound features. The normal sound out of the box is great. ' +
          'if you are bass heavy and want more, they have a bass up feature that really ' +
          'hits. They offer noise canceling to drown out background noises. With bass up ' +
          'and noise canceling on, this is where you get up to 30 hours of battery life. ' +
          'If you don\'t use these features you can get yourself up to 60 hours of battery. ' +
          'I personally have not needed the noise canceling feature yet and I feel like the ' +
          'sound in normal mode is great. I have easily gotten 40 hours out of these ' +
          'headphones. Louder volumes will affect the battery life as well. If you are an ' +
          'audiofile you may have different opinions than me, but I think these sound as ' +
          'good as Beats or other $100 plus headphones.</p> ' +
          '<br>' +
          '<h4>Build Quality</h4>' +
          '<p>These headphones are light and of course they adjust for different head sizes. ' +
          'They don\'t feel cheap, which I like. The memory foam cushion around the ears is ' +
          'comfortable. The thing I like the most is the fact that they are big enough to ' +
          'go around my ears instead of sitting on my ears like other headphones do. These ' +
          'are very comfortable well built headphones.</p> ' +
          '<br>' +
          '<h4>Conclusion</h4>' +
          '<p>The Anker Soundcore Life Q20 are a great pair of headphones. In my personal opinion ' +
          'they provide the sound a more expensive option would provide. They are comfortable ' +
          'and have great battery life. I would suggest this pair of headphones to anyone looking ' +
          'for an affordable but expensive sounding pair of headphones.</p>' +
          '<br>' +
          '<h4>Pictures: </h4>' +
          '<img class="review-image" src="../assets/img/anker/anker_headphone_box.png" alt="speaker box" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/anker/anker_headphone_box_back.png" alt="sperker box back" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/anker/anker_headphone_contents.png" alt="speaker box contents" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/anker/anker_headphone_full.png" alt="speaker front" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/anker/anker_headphone_side.png" alt="speaker interface" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/anker/anker_headphone_vol_play.png" alt="speaker interface" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/anker/anker_headphone_pwr.png" alt="speaker interface" ' +
          'height="275px" width="275px">' +
          '<br>' +
          '<h4>Link:</h4> +' +
          '<p><a href="https://www.amazon.com/Soundcore-Bluetooth-Headphones-Canceling-Playtime/dp/B07NM3RSRQ/ref=sr_1_1_sspa?' +
          'keywords=anker+headphones&qid=1566420722&s=gateway&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExSzlOVjNCRkJPOVVM' +
          'JmVuY3J5cHRlZElkPUEwODY0Mjk5M0dOMTVFVjJUNk01VSZlbmNyeXB0ZWRBZElkPUEwMzcyNzgwTTdBOEc0VkNQVTdVJndpZGdldE5hbWU9c3BfYXRmJmFj' +
          'dGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==" target="_blank">Amazon</a></p>'
      },
      {
        'id': 1,
        'details': '<h4>Tronsmart Element T6 Plus</h4>' +
          '<br>' +
          '<p>Tronsmart sent us a new item for review. The item is another bluetooth ' +
          'speaker. This time it\'s the Element T6 Plus! It is a decently sized speaker ' +
          'and looks very stylish. Features of the Element T6 Plus include: IPX6 water ' +
          'proofing, 3 types of EQ modes (vocals, 3D deep bass, and extra bass), speaker ' +
          'pairing (for connecting two of these speakers together), battery pack for ' +
          'phone charging, an SD card reader, and USB type C. The speaker is a little ' +
          'larger than others at this price point, but I believe it is because they are ' +
          'trying to make it a party speaker and they needed space for the battery pack ' +
          'for charging devices. The device is only $69.99 which is pretty good considering ' +
          'it comes with a battery pack inside it. </p>' +
          '<br>' +
          '<h4>Sound</h4>' +
          '<p>Let\'s start off with what everyone wants to know. How good is the sound? Well the ' +
          'sound isn\'t bad, but I feel like for its size and price tag it should be better. ' +
          'The 3 EQ modes do offer different types of listening and depending on the type of ' +
          'music you like I would say there is a sound that works for almost any genre. It ' +
          'does get loud, but the louder you go the less bass you here or feel. My favorite ' +
          'EQ mode was the extra bass mode. I listen to mostly alternative rock type of music ' +
          'and I found this mode the most pleasing to listen to. Now lets get something straight, ' +
          'I don\'t think it has bad sound, I just feel like I have reviewed other speakers in the ' +
          'same price range that sound either the same or a little better and most of the speakers ' +
          'are smaller than this one. To me that is saying something. It is more difficult to get ' +
          'good sound out of smaller speakers than to get good sound out of larger speaker. Don\'t ' +
          'think that I don\'t know that different speaker sizes do different things, but in the \n' +
          'case of a bluetooth speaker that is trying to sound good with a specific size of speaker ' +
          'for all music it would be easier to tune a larger speaker than a smaller one. JBL and BOSE ' +
          'are two brands on the more expensive side, but they make my case. They have very small ' +
          'bluetooth speakers in this same price range and I would argue that they sound better at ' +
          'higher volumes. Those speaker don\'t come with a battery pack for charging devices or any ' +
          'EQ features, so the Tronsmart does have some additional features that I would say still ' +
          'make it a worth while purchase at this price point. Connecting two of these together would ' +
          'definitely give you volume and sound quality you would want at a party. </p>' +
          '<br>' +
          '<h4>User interface</h4>' +
          '<p>I feel like it is important for me to talk about the way this speaker works. As with any ' +
          'bluetooth speaker, you turn it on and look for it in your bluetooth settings on your phone. ' +
          'Pretty straight forward. One end has a button/dial for pause/play and volume control. It ' +
          'works pretty good as a pause play button, but I feel like the volume dial needs some work. ' +
          'I noticed every time I went to either turn the volume up or down, that when I would turn the ' +
          'dial the volume would go in both directions. If you tried to turn it up, it would go up, but ' +
          'it would also go down on occasion making it hard to get it to the volume I wanted. The same ' +
          'went for turning it down. I feel like the dial needs some work to make sure it is changing the ' +
          'volume appropriately so that it is not a struggle to set the volume properly. This may just be ' +
          'my unit, but I would guess just because of how it feels while I turn it that it\'s this way for ' +
          'all of them. I like the idea of the dial for volume, but I want it to work better. Buttons along ' +
          'the side of the speaker are labeled well and easy to navigate. Track forward and back buttons, ' +
          'power button, EQ button, and the button to link two of theses speakers together.</p>' +
          '<br>' +
          '<h4>Overview Conclusion</h4>' +
          '<p>Overall for $69.99 I would say it is a pretty good little speaker. The average user would enjoy ' +
          'the sound and volume this speaker provides. Great for the person who wants to throw some parties ' +
          'and have a couple of them in different places in the house to provide a good music experience for ' +
          'everyone. The battery pack inside for charging other devices is a great feature and USB type C is ' +
          'fantastic. The fact that they are using the new technology is a good thing. I wish more companies ' +
          'would adopt USB type C as a standard. With the speaker being IPX6 water proof, these speakers are ' +
          'also great for an outdoor party. I would absolutely recommend this speaker to anyone looking for ' +
          'a party speaker under $100. Audiofiles would probably not be into this speaker as much. </p>' +
          '<br>' +
          '<h4>Pictures: </h4>' +
          '<img class="review-image" src="../assets/img/tronsmart/tronsmart_speaker_box.png" alt="speaker box" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/tronsmart/tronsmart_speaker_box_back.png" alt="sperker box back" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/tronsmart/tronsmart_speaker_contents.png" alt="speaker box contents" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/tronsmart/tronsmart_speaker_front.jpg" alt="speaker front" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/tronsmart/tronsmart_speaker_interface.png" alt="speaker interface" ' +
          'height="275px" width="275px">' +
          '<br>' +
          '<h4>Link:</h4> +' +
          '<p><a href="https://www.amazon.com/Waterproof-Bluetooth-Speakers-Tronsmart-Portable/dp/B07TNNT5Y2/ref=sr_1_4?keywords=' +
          'tronsmart+t6&qid=1564870661&s=gateway&sr=8-4" target="_blank">Amazon</a></p>'
      },
      {
        'id': 2,
        'details': '<h4>Aukey 20,000mah USB-C Power Bank</h4>' +
          '<br>' +
          '<p>Aukey sent us another USB-C power bank to review. Previously, ' +
          'they sent us a 10,000mah that is USB-C with two USB type A ' +
          'ports as well. One of those was Quick Charge 3.0 and the other ' +
          'Aukey\'s special adaptive charge. </p>' +
          '<br>' +
          '<p>This new one however is 20,000mah!!! This thing is a beast. It is ' +
          'about twice the size of the 10,000mah model, which makes sense ' +
          'since the power is twice as much. It doesn\'t seem like it is as big ' +
          'as it sounds. Now don\'t get me wrong, this thing is pretty big, but ' +
          'it is thin and really not that bad. If you are getting a 20,000mah ' +
          'power bank and expecting it to be tiny, good luck. That is a big ' +
          'battery and hard to make small currently. </p>' +
          '<br>' +
          '<p>Here is the thing, if you need a power bank that you can charge your ' +
          'phone with less than five times in a day, this is not your power bank. ' +
          'This thing will charge my Note 9 minimum five times. Not that it needs ' +
          'to be charged that much. The real power of this big battery is the fact ' +
          'that you can charge several devices at once and pretty much any device. </p>' +
          '<br>' +
          '<p>The Aukey 20,000mah USB-C power bank comes with a micro USB port as an ' +
          'input only, USB-C port that can be used as an input and an output. Three ' +
          'USB type A ports, one of which is Qualcom Quick Charge 3.0 and the other ' +
          'two are Aukey\'s adaptive charge, and finally it comes with a lightning port ' +
          'for Apple products. </p>' +
          '<br>' +
          '<p>Charge anything from phones, to tablets, to even laptops (just not very fast). ' +
          'Charge your phone and 4 of your friends phones all at once and still have some ' +
          'juice for your blue-tooth headphones later. </p>' +
          '<br>' +
          '<p>I have tested this item out on phones, tablets, and my Macbook pro. It does ' +
          'not charge my mac very fast, but if I have the laptop closed and plug it in ' +
          'when I don\'t need it, the next time I open it I will have plenty of battery. </p>' +
          '<br>' +
          '<p>I would recommend this to anyone that wants to be that person that has power ' +
          'all their friends and family. I will say that you may not want this as a ' +
          'walking around device. Like I mentioned before, it is big. If you are going to ' +
          'be carrying a purse, backpack, or briefcase that you can put it in, then it is ' +
          'light and thin enough for you to be the king of charging. Also to note it sells ' +
          'for around $40. That\'s a steal for this much power. </p>' +
          '<br>' +
          '<p>Another great product from Aukey. Great job Aukey! </p>' +
          '<br>' +
          '<img class="review-image" src="../assets/img/aukey/aukey_pwrbnk_big_box.png" alt="box" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/aukey_pwrbnk_big_wcable.png" alt="pwrbnk with cable" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/aukey_pwrbnk_big_ports.png" alt="ports" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/aukey_pwrbnk_big_lights.png" alt="lights" ' +
          'height="275px" width="275px">' +
          '<h4>Links:</h4>' +
          '<p><a href="https://www.amazon.com/AUKEY-Delivery-20000mAh-Portable-Compatible/dp/B07Q4QLL9N/ref=sr_1_5?crid=1KQBG7VSMV6CZ&' +
          'keywords=aukey+20000mah+power+bank&qid=1560395556&s=gateway&sprefix=' +
          'aukey+20%2Caps%2C282&sr=8-5" target="_blank">Amazon</a></p>' +
          '<p><a href="https://www.aukey.com/products/20000mah-power-bank-with-usb-c-lightning-input-pb-y14" target="_blank">Aukey</a></p>'
      },
      {
        'id': 3,
        'details': '<h4>Aukey Touch-Sensitve Table Lamp</h4>' +
          '<br>' +
          '<p>They Aukey LED touch-sensitive table lamp is a nice looking and easy to use ' +
          'lamp that has multiple brightness settings. The simplicity of the lamp makes ' +
          'it hard to write a whole lot about it. It is a modern looking lamp that can be ' +
          'used at the bedside or in any room. Simply touch the top once to turn it on or ' +
          'off. Once on, touch and hold to change the brightness levels. Once max ' +
          'brightness or dimmness is reached, a small blue LED light flashes at the base ' +
          'of the lamp. Aukey has sales all the time on all of their products. This lamp ' +
          'is only $34.99 and when Aukey has a sale, it dips into the 20\'s.</p>' +
          '<br>' +
          '<h4>Conclusion:</h4> ' +
          '<p>If you are looking for a nice modern looking LED lamp that is touch sensitive ' +
          'and has a range of brightness settings, this is a great lamp to go with. It is ' +
          'very simple to use and just looks nice. I would recomend this lamp for bedside ' +
          'and small room use. Great product from the Aukey team.</p>' +
          '<br>' +
          '<img class="review-image" src="../assets/img/aukey/aukey_lamp_box.png" alt="lamp box" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/aukey_lamp.png" alt="lamp" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/aukey_lamp_cord.png" alt="cord" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/aukey_lamp_bright.png" alt="bright" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/aukey_lamp_dim.png" alt="dim" height="275px" width="275px">' +
          '<h4>Links:</h4>' +
          '<p><a href="https://www.amazon.com/AUKEY-Touch-Sensitive-Dimmable-Lighting-Function/dp/B07L8BTSQ2/ref=sr_1_2_sspa?' +
          'keywords=aukey+lamp&qid=1559865758&s=gateway&sr=8-2-spons&psc=1" target="_blank">Amazon</a></p>' +
          '<p><a href="https://www.aukey.com/products/touch-sensitive-table-lamp" target="_blank">Aukey</a></p>'
      },
      {
        'id': 4,
        'details': '<h4>Aukey 10\,000mah USB-C Power Bank</h4>' +
          '<br>' +
          '<p>The Aukey USB-C power bank is pretty good from my testing. I tested it ' +
          'with a Samsung Galaxy Note 9 and also used other branded cables along ' +
          'with the cable provided.</p>' +
          '<br>' +
          '<p>To start off, lets talk about all the charging ports this little device ' +
          'has. It comes with four separate charging ports. One is a standard USB ' +
          'port that will charge devices fairly quickly. The second is a standard ' +
          'USB port with Quick Charge 3.0 Tech. Now, I am pretty sure I got a ' +
          'defective USB Quick Charge port on mine. What I mean is this, I have ' +
          'USB Quick Charge 3.0 power supplies from Aukey and when I plug my phone ' +
          'in, Fast Charging shows up on my screen. With this battery pack, that did ' +
          'not happen. It could be something else, but I tried multiple cables and ' +
          'made sure that the power bank was fully charged. I am chalking this up to ' +
          'a defective part on my unit. Again, I have purchased other USB Quick Charge ' +
          '3.0 items from Aukey and didn\'t have this problem. I am guessing I just ' +
          'got a bad one. (Since I have upgraded a lot of my cables to being USB-C on ' +
          'both ends I am not personally upset by this.) Moving on. The third port is ' +
          'USB-C. This passed my Fast Charge test. Worked with all of my different ' +
          'cables. I should note that you can also charge the power bank through the ' +
          'USB-C port as well as the micro USB port on the side. The fourth port is ' +
          'on the side and is micro USB as stated before and it is a dedicated port for charging the power bank.</p> ' +
          '<br>' +
          '<p>I want you to understand why I only tested with a single phone. I have worked ' +
          'in the cell phone industry and I am a Computer Science major who is super ' +
          'techy. I follow all the new phone releases and look at all the specs to see ' +
          'what is new and what cool tech I can take advantage of. I have not owned a ' +
          'phone longer than a year at a time. I like trying different phones and ' +
          'testing different features that different companies have to offer. I have ' +
          'found Samsung to be my favorite in terms of quality and useful features. I ' +
          'also know that Samsung has the latest tech in their latest devices. If this ' +
          'power bank works with my phone it will work with all others. </p>' +
          '<br>' +
          '<p>Returning to the product at hand, this is a 10,000mah power bank. That means ' +
          'with my phone I can get two full charges out of it. Smaller phones like the ' +
          'Galaxy S9 or the standard iPhone will get three or more. With a $30 price tag ' +
          'and knowing that Aukey has sales on these items all the time it is a great ' +
          'little power bank at a decent price.</p> ' +
          '<br>' +
          '<h4>Conclusion:</h4> ' +
          '<p>All the above is all you really need to know about this power bank. I would \n' +
          'recommend it to anyone looking for a portable power bank. It is small and \n' +
          'has enough juice to give you a couple days on the road.</p>' +
          '<br>' +
          '<img class="review-image" src="../assets/img/aukey/power-bank-box.png" alt="pwrbnk box" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/power-bank-contents.png" alt="contents" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/power-bank-and-cable.png" alt="pwrbnk and cable" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/power-bank-ports.png" alt="ports" ' +
          'height="275px" width="275px">' +
          '<h4>Links:</h4>' +
          '<p><a href="https://www.amazon.com/AUKEY-10000mAh-Portable-Delivery-Nintendo/dp/B079Z4RHZZ/ref=sr_1_1_sspa?ie' +
          '=UTF8&qid=1547868521&sr=8-1-spons&keywords=Aukey+usb+c+power+bank&psc=1" target="_blank">Amazon</a></p>'
      },
      {
        'id': 5,
        'details': '<h4>Aukey Wireless Fast Charger</h4>' +
          '<br>' +
          '<p>The Aukey Wireless Fast Charger does just what you would think it ' +
          'should do. The only thing I feel like most people need to know is ' +
          'that it does not come with the wall plug. The unit only costs $16 ' +
          'so Aukey want\'s you to pay for the wall plug to go with it. Or you ' +
          'can use the plug that came with your phone. Aukey\'s Quick Charge 3.0 ' +
          'wall plug is the plug I would suggest if you want fast charge capability. ' +
          'That is, if you are going to buy one. If you have a Samsung phone, ' +
          'like I do, it comes with a fast charging wall plug that you could use.</p> ' +
          '<br>' +
          '<p>This works like any other wirless charger. Plug it in, set your phone ' +
          'on it and let your phone charge. I used a Galaxy Note 9 for testing and ' +
          'it passed all my tests. Now I don\'t have bulky cases for my phone. I ' +
          'always buy the thinner cases because, I use the NFC Payment solutions ' +
          'provided by the phone and big bulky cases block that from working. I ' +
          'can only imaging charging the phone wirelessly would have issues if ' +
          'you had a big bulky case. None of my cases had issues. Again I use thin ' +
          'cases.</p> ' +
          '<br>' +
          '<p>There isn\'t much else to say about this product. It wirelessly charges ' +
          'compatible phones and fast charges if you have compatible phones and wall ' +
          'adapters. The price tag is less than most, which is the most appealing thing ' +
          'about this charger. It works like the expensive ones and costs half the price ' +
          'or less. I also own fast wireless chargers from Samsung, Anker, and Senio. All ' +
          'work the same, but the Aukey is the most affordable.</p> ' +
          '<br>' +
          '<h4>Conclusion:</h4>' +
          '<p>I would absolutely recommend this amongst other fast wireless chargers. It ' +
          'works the same as the expensive ones and costs much less. There is no reason ' +
          'to spend more money when they all do the same thing. If you are looking into ' +
          'a fast wirless charger, get this one.</p>' +
          '<br>' +
          '<img class="review-image" src="../assets/img/aukey/wireless-charge-and-cable.png" alt="charger and cable" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/wireless-charger-box.png" alt="charger box" ' +
          'height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/wireless-charger-contents.png" alt="contents" ' +
          'height="275px" width="275px">' +
          '<h4>Links:</h4>' +
          '<p><a href="https://www.amazon.com/AUKEY-Wireless-Charging-Compatible-Qi-Enabled/dp/B07KPWQZXP/ref=sr_1_1_' +
          'sspa?ie=UTF8&qid=1547868578&sr=8-1-spons&keywords=Aukey+wireless+fast+charger&psc=1" target="_blank">Amazon</a></p>'
      },
      {
        'id': 6,
        'details': '<h4>Aukey Dash Cam</h4>' +
          '<br>' +
          '<p>The Aukey Dash Cam. They have a few different models. The one I ' +
          'am reviewing is the AUKEY Dash Cam, 1080p Dash Camera for Cars ' +
          'with 6-Lane Lens, 2.7 Inch LCD, Motion Sensor, Loop Recording and ' +
          'Night Vision. Runs around $40. So not an expensive item. I was ' +
          'able to test this out in my truck. It was raining out the day I ' +
          'used it which is pretty good testing conditions for camera quality.</p>' +
          '<br>' +
          '<p>The camera came with a bunch of mounting stuff and a car adapter for ' +
          'power. The cord is super long. This is awesome because they give you ' +
          'everything you need to mount it in the center of your windshield and ' +
          'then mount the cable along the edges of the windshield so it is hidden ' +
          'out of the way. I am super impressed with the included items to make ' +
          'using the camera convenient and nice. The camera does require you to ' +
          'have a micro sd card, but that is something you will find with most ' +
          'dash cams.</p>' +
          '<br>' +
          '<p>For the Camera itself, I didn\'t even look at the instructions and figured ' +
          'out how to use it. So, A+ to Aukey for making the camera easy to figure ' +
          'out. I imagine that anyone some what techy could figure it out, but Aukey ' +
          'does provide instructions on how to work the camera for anyone that needs ' +
          'them. The picture quality is actually really nice. Like I said I used it ' +
          'on a rainy day for my test and it performed well. The picture was stable ' +
          'and really clear. I am actually not sure how to express how good the ' +
          'picture is on a camera that cost $40. I mean it is not professional quality ' +
          'by any means, but it is definitely 1080p and I mean, cmon, $40!? I like ' +
          'this Dash Cam.</p>' +
          '<br>' +
          '<p>Some of the settings I played with were the video quality settings. You can ' +
          'have it at 1080p or lower. They also provide an option for 50hz or 60hz. ' +
          'You can choose to have a time stamp or not. You can choose the video loop ' +
          'intervals. Mine was on the default of 3 min. This means it starts a new ' +
          'video file every 3 min. You can go as long as 10 min. Then there were some ' +
          'other settings I wasn\'t ready to mess with, but they pack a lot into this ' +
          'little camera. The LCD screen on the back is super nice. I mean Aukey just ' +
          'did a great job on this little inexpensive camera.</p>' +
          '<br>' +
          '<h4>Conclusion:</h4>' +
          '<p>I would recommend this dash cam to anyone. I am excited to use it all of the ' +
          'time. I like the idea of having proof if something were to happen on the ' +
          'road and I knew it wasn\'t my fault. Or even to catch funny things you commonly ' +
          'come across while driving. Let the camera do the job and then I don\'t have to take ' +
          'my eyes off the road. I can go back and look later. Great product Aukey!</p>' +
          '<br>' +
          '<h4>Pictures/Video:</h4>' +
          '<br>' +
          '<img class="review-image" src="../assets/img/aukey/dash-cam-1.png" alt="camera" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/dash-cam-2.png" alt="camera" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/dash-cam-3.png" alt="camera" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/dash-cam-4.png" alt="camera" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/dash-cam-5.png" alt="camera" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/dash-cam-6.png" alt="camera" height="275px" width="275px">' +
          '<img class="review-image" src="../assets/img/aukey/dash-cam-7.png" alt="camera" height="275px" width="275px">' +
          '<h4>Links:</h4>' +
          '<p><a href="https://www.amazon.com/AUKEY-Camera-6-Lane-Motion-Recording/dp/B07HVNCQF3/ref=sr_1_8?s=car&ie=UTF8&' +
          'qid=1547777365&sr=1-8&keywords=aukey+dash+cam" target="_blank">Amazon</a></p>'
      },
      {
        'id': 7,
        'details': '<h4>Tronsmart Element Mega</h4>' +
        '<br>' +
        '<p>Today I received a bluetooth speaker from Tronsmart. The Element Mega. My initial' +
        'thoughts were, "What a nice sounding speaker." Now, The speaker does sound nice. ' +
        'The louder it gets the less bass you hear, which is normal for speakers in this ' +
        'genre. The sound was very clear and very loud. Using this thing outside would suit ' +
        'most peoples needs. As a general review comparing this with other speaker similar ' +
        'that I own, I would say it is about the same. The thing I would say is that when ' +
        'giving something the name "Mega" I would think that it would be capable of more ' +
        'when it comes to bass. If Tronsmart was going for "Mega" in the loudness capabilities, ' +
        'they nailed it. It is much louder than some of my smaller speakers and clear to hear. ' +
        'As I said earlier it is expected to get bass drop when turning it up, but I have heard ' +
        'speakers about this size not called "Mega" or giving any indication that it should be ' +
        '"greater" than other speakers that have better bass at louder volumes. Overall though, ' +
        'I really like the speaker. I like how clear it is when at max volume. I play outdoor ' +
        'volleyball and this thing is plenty loud to give the area music at enjoyable quality. ' +
        'The last thing I want to give mention to is the modes this speaker has. Yes it\'s a ' +
        'bluetooth speaker, but Tronsmart went ahead and added a spot for a micro sd card and ' +
        'a line in for 3.5mm head jack. Pretty cool. I like the idea of putting music on an sd ' +
        'card and not having to worry about connecting a phone. This would only be convenient ' +
        'if the sd card was a mix. There is no navigation. So if you are one to listen to albums ' +
        'at a time and you want to pick what to listen to, just use the bluetooth feature. They ' +
        'make it super easy with NFC connection.  </p>' +
        '<br>' +
        '<h4>Comparisons:</h4>' +
        '<p>I have a JBL flip 3, JBL clip 2, Anker Soundcore, and UE MegaBoom. This speaker from ' +
        'Tronsmart is absolutely better than the Anker and JBL clip 2, but those are smaller ' +
        'speakers and I would expect nothing less than better quality out of the Tronsmart. ' +
        'Compared to the JBL flip 3 and UE MegaBoom, I would have to say that they all sound ' +
        'very similar. This was kinda of a disappointment cuz I wanted the Tronsmart to out ' +
        'perform these speaker since it is called the "Mega". The UE MegaBoom would be the ' +
        'exception. Same consept. All 3 of these are very similar in sound though. The louder ' +
        'they get the less bass. At a normal volume they are all very comparable. So if they ' +
        'are all comparable, what should you do? The Tronsmart doesn\'t cost as much as the ' +
        'other two speakers. So get the Tronsmart. There is only one advantage to the higher ' +
        'cost of the JBL Flip series and the UE MegaBoom series, water resistance. Tronsmart ' +
        'doesn\'t have anything listed in the way of water resistance for this speaker and I ' +
        'don\'t really want to test it myself. If you want a quality speaker at a great price ' +
        'look no further I really like this speaker. </p>' +
        '<br>' +
        '<h4>Conclusion:</h4>' +
        '<p>I would recomend this speaker to anyone looking for a good, loud, clear speaker. If you ' +
        'need something for your outdoor parties or just to fill a room with music, this is a ' +
        'great option. The price is hard to beat for the quality you get here. The additional modes ' +
        'this speaker has on top of bluetooth is a nice touch. </p>' +
        '<br>' +
        '<h4>Pictures:</h4>' +
        '<img class="review-image" src="../assets/img/tronsmart/tronsmart_box.png" alt="box" ' +
          'height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/tronsmart/tronsmart_speaker.png" alt="speaker" ' +
          'height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/tronsmart/tronsmart_speaker_and_box.png" alt="spkr and box" ' +
          'height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/tronsmart/tronsmart_speaker_top.png" alt="speaker top" ' +
          'height="275px" width="275px">' +
        '<h4>Links:</h4>' +
        '<p><a href="https://www.amazon.com/dp/B07HQ99VQ5/?tag=tronsmart-20" target="_blank">Amazon</a></p>'
      },
      {
        'id': 8,
        'details': '<h4>Aukey Gaming Keyboard</h4>' +
        '<p>The Aukey Gaming Keyboard. What an amaizing keyboard for the price! I can\'t get over how much I like how this keyboard ' +
        'feels when I am typing. It feels so premium and I haven\'t had a single issue with response. I have owned a lot of logitech ' +
        'keyboards and as much as I like them, this keyboard is outstanding. It is a wired keyboard so if you are looking for a ' +
        'wireless keyboard, please look elsewhere. After using this keyboard for a couple hours of gaming I have no complaints.</p>' +
        '<br>' +
        '<h4>Details:</h4>' +
        '<p>Lets start with the how the keys feel. When typing they have this light yet firm feel that makes them seem like they ' +
        'will go a long ways before wearing out. They are just right in my opinion with how hard I need to press to get the keys ' +
        'to respond. I mean this keyboard just feels awesome. As premium as other gaming keyboards I\'ve used, and this keyboard ' +
        'is up to half the cost of some of the other popular gaming keyboards.</p>' +
        '<br>' +
        '<p>Next, lets talk RGB Colors. It comes with quite a few preset settings that are all pretty cool. It has light up ' +
        'configurations for five different types of games which include FPS, CP, COD, LOL or Racing games. There are 10 different ' +
        'color configurations that are for normal typing. One of my favorites is when you hit a key the keyboard rainbows out ' +
        'from that key to the rest of the keyboard. This is just good fun while typing. You can also configure your own personal ' +
        'settings for what you want it to do while you type or what keys you want lit up for a specific game. You can also choose ' +
        'the color for each individual key. It is pretty sweet.</p>' +
        '<br>' +
        '<p>There is one thing about this keyboard that I haven\'t been able to test, but it concerns me a little. The keys are kinda ' +
        'loud when you type. I like it, but it concerns me. Why am I concerned? Chatting. I am worried that when in game the keys ' +
        'may be loud enough to be a disruption when playing with other people and you have a mic set up to talk to them. Now I know ' +
        'that if you have a condenser mic this shouldn\'t be an issue, but I am still wondering just how the key sounds will affect ' +
        'in game chat. Once I get a chance I will update this post with exactly how the key press sounds affect chat.</p>' +
        '<br>' +
        '<h4>Bottom Line:</h4>' +
        '<p>Seriously, this is a great keyboard and fun to use. It only costs $65 and Aukey likes to have sales, promo codes, or ' +
        'coupons to make it even cheaper. If you asked me is it worth the $65 and no discount, I would say absolutely. It is still ' +
        'priced much cheaper than other gaming keyboards, that to me, feel almost the same, and function the same. If you are looking ' +
        'for a good gaming keyboard and want to save money at the same time, get this one.</p>' +
        '<br>' +
        '<p>See photos at the bottom of this post to see different color schemes I played with and liked. Reminder there are many ' +
        'more than I have pictures for.</p>' +
        '<br>' +
        '<h4>Pictures:</h4>' +
        '<img class="review-image" src="../assets/img/aukey/keyboard_box.png" alt="box" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/keyboard.png" alt="keyboard" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/keyboard_lights_1.png" alt="lights 1" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/keyboard_lights_2.png" alt="lights 2" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/keyboard_lights_3.png" alt="lights 3" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/keyboard_lights_4.png" alt="lights 4" height="275px" width="275px">' +
        '<h4>Links:</h4>' +
        '<p><a href="https://www.amazon.com/AUKEY-Mechanical-Customizable-Aluminium-Compatible/dp/B01IRASQA0/' +
          'ref=sr_1_2_sspa?ie=UTF8&qid=1542411540&sr=8-2-spons&keywords=aukey+keyboard&psc=1" target="_blank">Amazon</a></p>'
      },
      {
        'id': 9,
        'details': '<h4>Aukey USB C Charging Cable For MacBooks</h4>' +
        '<p>For this review I compared the Aukey USB C charging cable for MacBooks to the stock MacBook charging cable. ' +
        'I used a program called battery health to see what wattage the cable would charge my 15" MacBook Pro at. ' +
        'You can see the results in the images attached to this review. To sum it up for you in words, the stock cable ' +
        'is a better cable. It charged at a higher wattage than the Aukey cable. Now this isn\'t to say that the Aukey ' +
        'cable isn\'t a good cable. For the price at which they are selling the cable, I would by the Aukey any day of ' +
        'the week. I wasn\'t getting as fast of a charge, but it\'s a cable I could keep at home when I don\'t need to charge ' +
        'at full speed. It seats in the USB-C port nice, with that little click you would expect. It doesnt feel loose or ' +
        'anything like that. It is a quality cable, but it seems the power output is not as strong when using this cable. ' +
        'Now It could be that the program I used didn\'t acurately read the wattage going in also. I actually got different ' +
        'readings from both cables the first time I plugged them in and tried unplugging and plugging back in a few times ' +
        'until I got what seemed to be acurate or consistant results.</p>' +
        '<br>' +
        '<h4>Conclusion: </h4><br>' +
        '<p>I would recomend this cable. It is a quality build and puts out enough power that it is definitely worth the buy.</p>' +
        '<br>' +
        '<h4>Pictures:</h4>' +
        '<img class="review-image" src="../assets/img/aukey/aukey-charge-cable.png" alt="cable" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/aukey-cable-plugged-in.png" alt="plugged in" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/aukey-cable.png" alt="cable 2" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/apple-stock-cable.png" alt="stock cable" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/stock-cable.png" alt="stock cable 2" height="275px" width="275px">'
      },
      {
        'id': 10,
        'details': '<h4>Aukey USB-C To USB 3.0 Hub</h4>' +
        '<p>To start things off, this hub is slim and compact. It is made from a plastic material, so it ' +
        'doesn\'t feel as premium as it could. On the other hand it makes it super light weight. In this ' +
        'review I am going to compare speed tests using an Aukey USB C to USB 3.0 adapter and a Kingston ' +
        'USB 3.0 flash drive. After I run speed tests on this I will use the hub and the same flash drive ' +
        'to see if Aukey made the hub as good as the adapter. I have had the adapter for a while and the ' +
        'speeds are great. It is a really good adapter and inexpensive as well. Depending on the types of ' +
        'drives you will be using will also very the performance. Kingston in my opinion seems to not ' +
        'actually produce the speeds a USB 3.0 flash drive should, but it is still really fast. A San disk ' +
        'drive would probably give more acurate readings, but this is what I have to work with. See the ' +
        'images attached to the review to see the results. </p>' +
        '<br>' +
        'The speed test program I am using is testing the capabilities of the flash drive for video read and ' +
        'write capabilities. How fast can it write a file and how fast can it read. It will tell us whay video ' +
        'quality it is capable of. If you look at the images attached to the review of the speed tests, you ' +
        'will see that the hub performed slightly better than the adapter. The adapter can read up to 2160p at ' +
        '25 frames per second. While the hub can read up to 2160p and 30 frames per second. So when deciding ' +
        'what to buy from aukey, you can consider what you may be doing with the product. If you plan on ' +
        'watching 4k content from a flash drive, I would say get the hub. If less than 4k the adapter is a ' +
        'cheaper option and still produces very fast read speeds. Remember this is using a Kingston flash drive. ' +
        'You can see that the write tests were no where near what they should be for a 3.0 drive, but I attribute ' +
        'that to the flash drive itself, not the hub or the adapter. Results will vary based on the type of drive ' +
        'you test with and use. Sandisk performs better for me, but I didn\'t have one on hand to test with.' +
        '<br>' +
        'For those wondering, it is not a powered hub. You can use it to charge a phone or something but it will work ' +
        'as if you had plugged it into your computer to charge it. Other hubs have a wall adapter so that you can use them ' +
        'as chargers without having to plug them into a computer. That is not the purpose of this hub. It is for transfering ' +
        'files from computer to drive or vice versa.' +
        '<br>' +
        '<h4>Conclusion: </h4><br>' +
        '<p>Conclusion, this is a fantastic hub and comes at a good price point compared to other USB C to USB 3.0 hubs you will ' +
        'find. It has good throughput and won\'t disappoint in performance. I would recommend this product to others.</p>' +
        '<br>' +
        '<h4>Pictures:</h4>' +
        '<img class="review-image" src="../assets/img/aukey/aukey-hub-box.png" alt="box" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/aukey-hub.png" alt="hub" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/aukey-hub-with-drive.png" alt="with drive" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/aukey-adapter-with-drive.png" alt="adapter" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/adapter-test.png" alt="test" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/aukey/hub-test.png" alt="test 2" height="275px" width="275px">' +
        '<h4>Links:</h4>' +
        '<p><a href="https://www.amazon.com/AUKEY-Adapter-MacBook-Chromebook-Pixelbook/dp/B0772TXG3H/ref=sr_1_3?ie=' +
        'UTF8&qid=1534214636&sr=8-3&keywords=aukey+usb+c+hub" target="_blank">Amazon</a></p>'
      },
      {
        'id': 11,
        'details': '<h4>Anker Soundcore 2</h4>' +
        '<p>Anker sent me a Soundcore 2 bluetooth speaker to test and compare. The other bluetooth speakers I have ' +
        'to compare the Soundcore2 with are the JBL flip 3 and the JBL clip 2. Now all three of these are different ' +
        'sizes. So the comparison might not be 100% fair, but we will try to work around that.</p> ' +
        '<br>' +
        '<p>Lets talk about this Anker speaker for a second. First of all, it is pretty small. It is not so small ' +
        'that you would consider it to be a carry around speaker like the JBL clip 2. It is designed more for saving ' +
        'space on a table or desk. Not to be confused, it is portable, but the clip 2 is something you clip to a backpack or belt loop. ' +
        'Where the Anker is something you can take with you and set it somewhere to listen to it. When I saw how small it was I ' +
        'thought to myself, "Well this probably doesn\'t produce ' +
        'super great sound." I will tell you right away, I was wrong. For the size of this thing, it produces fantastic ' +
        'sound. It gets increadibly loud for its size, and when turned all the way up there is no distortion or ' +
        'crackling. The overall sound is great. It is not super bass heavy. I expected this with such a small design, ' +
        'but it does produce a good rounded sound. If you use an equalizer you can make it sound even better. The stock ' +
        'sound is really good. It has IPX5 water resistance, and rated for 24 hours play time. I have not been able to test ' +
        'the battery life. It also comes in three colors: black, red, and blue.</p>' +
        '<br>' +
        '<p>Now lets consider what this device costs. $40 on Amazon, for a bluetooth speaker of this quallity is a great ' +
        'price. If you want a quality speaker for an afordable price, don\'t hesitate to look at this one.</p> ' +
        '<br>' +
        '<p>Lets do some comparison. Compared to the JBL flip 3, it holds its own, but the flip 3 sounds a lot better. Now ' +
        'lets remember that the flip 3 is a bigger speaker. It has built in woofers for bass and with an overall bigger size, ' +
        'the types of speakers they can use is different as well. I would say this is not a fair comparison if you are asking ' +
        'which one is better. The JBL sounds much better overall. It is more rounded and has more bass without any tweaking. ' +
        'Now having said that, the JBL costs $100. For the price the Anker is in a league of its own. Now lets consider ' +
        'loudness. They both get increadibly loud. Neither produce any kind of distortion or crackle. The flip 3 is louder, ' +
        'but again, bigger and more expensive. The only benefit this gives is if you are using it outside and need to hear it ' +
        'from a long way off. The Anker gets plenty loud and everyone in your house will definitely hear it if you turn it up.</p> ' +
        '<br>' +
        '<p>Now on to the JBL clip 2 vs. the Anker Soundcore 2. Again I would argue not a fair comparison for the clip 2. ' +
        'The clip 2 is smaller than the anker and therefore the sound is not the same. The clip 2 produces amazing sound ' +
        'for its size. I would say if the Anker were the same size the clip 2 would win out. It has great rounded sound and ' +
        'gets very loud for its size. The difference again is in the size of the speakers these companies are capable of ' +
        'fitting in these products. Anker\'s speaker is louder and very clean to listen to, but the clip two has a more ' +
        'rounded sound. Again the Anker can be tweaked with some EQ settings, but I think most of us are looking to turn ' +
        'on a speaker and start listening without having to change a thing. This is where JBL wins out. Now the JBL clip ' +
        '2 is $50. This is still more expensive than the anker. If you are looking to spend less money and get the best ' +
        'sound for that money, get the Anker Soundcore 2. </p>' +
        '<br>' +
        '<h4>Pros and Cons:</h4><br>' +
        '<p>Pros: Price, size, gets really loud, no distortion.<br>' +
        'Cons: EQ tweaks for better sound.</p>' +
        '<br>' +
        '<h4>Conclusion: </h4><br>' +
        '<p>The Anker Soundcore 2 is a fantastic little speaker. It gets really loud and is very clear. For those ' +
        'that want more bass without tweaking, or for those that don\'t know how to make those tweaks and want more bass, ' +
        'look into the clip 2 if you still want something in the same price range, otherwise get the flip 3. I like this ' +
        'little speaker a lot and plan on getting a few as gifts for others in my family because it is affordable and has ' +
        'great sound. </p>' +
        '<br>' +
        '<h4>Pictures:</h4>' +
        '<img class="review-image" src="../assets/img/anker/anker-box.png" alt="box" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/anker/anker-in-box.png" alt="in box" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/anker/anker-speaker-one.png" alt="speaker 1" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/anker/anker-speaker-two.png" alt="speaker 2" height="275px" width="275px">' +
        '<img class="review-image" src="../assets/img/anker/speaker-compare.png" alt="compare" height="275px" width="275px"><br>' +
        '<h4>Links:</h4>' +
        '<p><a href="https://www.anker.com/products/variant/soundcore-2-bluetooth-speaker/A3105011" target="_blank">Anker</a><br>' +
        '<a href="https://www.amazon.com/Anker-Soundcore-Bluetooth-Exclusive-Water-Resistant/dp/B01MTB55WH/ref=sr_1_3?ie=UTF8&qid=' +
        '1531153505&sr=8-3&keywords=anker+soundcore+2" target="_blank">Amazon</a></p>'
      },
      {
        'id': 12,
        'details': '<p>It\'s here everybody! The app is finally available for all on the Apple App Store. Follow ' +
        '<a href="https://itunes.apple.com/us/app/safe-sets/id1396956632?ls=1&mt=8" target="_blank">this</a> link to get it now! ' +
        'We are so happy for this announcement. It has been a long process and we could not be happier to share this news with you.</p>'
      }
      // COPY THIS FOR ADDING MORE ARTICLES
      // {
      //   'id': 0,
      //   'details': ''
      // },
    ];
  }

  setIndex(index) {
    this.index = index;
  }
  setFeatured(isFeatured) {
    this.isFeatured = isFeatured;
  }

  getIndex() {
    return this.index;
  }

  getFeatured() {
    return this.isFeatured;
  }
}
